import { FC, useState } from 'react';
import { CloseIcon, IconButton, Input, SearchIcon } from '../../toolkit';
import { debounce } from 'lodash';

type Props = {
  placeholder: string;
  onChange: (searchTerm: string) => void;
}

const SearchInput: FC<Props> = ({ placeholder, onChange }) => {
  const [searchTem, setSearchTerm] = useState<string>('');

  const triggerSearch = debounce((search: string) => {
    onChange(search);
  }, 500);

  return (
    <Input 
      type='search'
      value={searchTem}
      placeholder={placeholder}
      onChange={e => {
        const search = e.target?.value || '';
        setSearchTerm(search);
        triggerSearch(search);
      }}
      startIcon={<SearchIcon />}
      endButton={
        <IconButton onClick={() => {
          setSearchTerm('');
          triggerSearch('');
        }}>
          <CloseIcon />
        </IconButton>
      }
    />
  );
};

export default SearchInput;