import { FC } from 'react';
import { TitanClient } from '../../../models';
import { useClient } from '../../hooks';
import { Container, Responsive } from '../../../layout';
import { Button, Input } from '../../../toolkit';
import { textForm } from '../../../translations';

type Props = {
  client?: TitanClient;
  onClose: () => void;
  onSaved: () => void;
};

const ClientForm: FC<Props> = ({ client, onClose, onSaved }) => {
  const { clientForm, onChange, onSubmit} = useClient(onSaved, client);
  return (
    <div>
      <form noValidate onSubmit={onSubmit}>
        <Responsive colsXs={2}>
          <Input
            label={textForm.lastNameLabel}
            placeholder={textForm.lastNamePlaceholder}
            name="lastName"
            onChange={onChange}
            value={clientForm.form.lastName}
            required
            errorMessage={clientForm.errors.lastName}
          />
          <Input
            label={textForm.firstNameLabel}
            placeholder={textForm.firstNamePlaceholder}
            name="firstName"
            onChange={onChange}
            value={clientForm.form.firstName}
            required
            errorMessage={clientForm.errors.firstName}
          />
        </Responsive>

        <Responsive colsXs={3} spanFirst>
          <Input
            label={textForm.addressLabel}
            placeholder={textForm.addressPlaceholder}
            name="address"
            onChange={onChange}
            value={clientForm.form.address}
            required
            errorMessage={clientForm.errors.address}
          />
          <Input
            label={textForm.cityLabel}
            placeholder={textForm.cityPlaceholder}
            name="city"
            onChange={onChange}
            value={clientForm.form.city}
            required
            errorMessage={clientForm.errors.city}
          />
        </Responsive>
        <Input
          label={textForm.emailLabel}
          placeholder={textForm.emailPlaceholder}
          name="email"
          onChange={onChange}
          value={clientForm.form.email}
          required
          errorMessage={clientForm.errors.email}
        />

        <Input
          label={textForm.phoneLabel}
          placeholder={textForm.phonePlaceholder}
          name="phone"
          onChange={onChange}
          value={clientForm.form.phone}
          required
          errorMessage={clientForm.errors.phone}
        />

        <Responsive colsXs={3} spanFirst>
          <Input
            label={textForm.bankAccountLabel}
            placeholder={textForm.bankAccountPlaceholder}
            name="bankAccount"
            onChange={onChange}
            value={clientForm.form.bankAccount}
            errorMessage={clientForm.errors.bankAccount}
          />
          <Input
            label={textForm.fiscalCodeLabel}
            placeholder={textForm.fiscalCodePlaceholder}
            name="fiscalCode"
            onChange={onChange}
            value={clientForm.form.fiscalCode}
            errorMessage={clientForm.errors.fiscalCode}
          />
        </Responsive>

        <Input
          label={textForm.notesLabel}
          placeholder={textForm.notesPlaceholder}
          name="notes"
          onChange={onChange}
          value={clientForm.form.notes}
          rows={5}
        />

        <Container gap={10} horizontalAlign='end'>
          <Button size='medium' variant='outlined' type="button" onClick={onClose}>Renunta</Button>
          <Button size='medium' variant='contained' type='submit'>Salveaza</Button>
        </Container>
      </form>
    </div>
  );
};

export default ClientForm;