import { useList as useListLegacy } from '../table';
import { TableProps } from '../types';

const usePaginatedList = (url: string): TableProps => {
  const listProps = useListLegacy(url);

  const setSearchTerm = (searchTerm?: string) => listProps.setSearchTerm(searchTerm || '');

  return {
    loading: listProps.listProps.loading,
    isFiltered: Boolean(listProps.listProps.queryParams?.searchTerm),
    page: 0,
    results: listProps.listProps.results,
    total: listProps.listProps.results.length,
    setSearchTerm,
    setQueryParam: listProps.setQueryParam,
    reload: listProps.reload,
  };
};

export default usePaginatedList;