import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AddIcon,
  Button,
  UserAddIcon,
  ContainerFlex,
  Portal,
  Typography,
} from "../../../toolkit";
import { useCacheList } from "../../../lib";
import { TitanClient } from "../../../models";
import { SearchInput } from "../../../components";
import ClientForm from "../ClientForm/ClientForm";
import styles from "./styles.module.css";
import { setRefreshClients, useAppSelector } from "../../../store";
import ClientListTable from "./ClientListTable";

interface IDocument extends TitanClient {
  key: string;
}

const filter = (searchTerm: string) => (row: IDocument) => {
  return `${row.firstName} ${row.lastName} ${row.email} ${row.phone}`
    .toLowerCase()
    .includes(searchTerm.toLowerCase());
};

const ClientList: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const clients = useAppSelector((state) => state.cache.clients);
  const user = useAppSelector((state) => state.auth.user);
  const listProps = useCacheList(clients, filter, setRefreshClients);

  return (
    <>
      {(listProps.total > 0 || listProps.isFiltered) && (
        <ContainerFlex style={{ width: 1200, margin: "0 auto" }}>
          <div className={styles.tableHeader}>
            <Typography type="h5" variation="center">
              {clients.data?.length} Medici
            </Typography>
            <div className={styles.tableControls}>
              <SearchInput
                placeholder="Cauta medici"
                onChange={(searchTerm) => {
                  listProps.setSearchTerm(searchTerm);
                }}
              />
              {user?.isAdmin && (
                <div>
                  <Button
                  type='button'
                    size="medium"
                    variant="contained"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    <AddIcon />
                    Adauga medic
                  </Button>
                </div>
              )}
            </div>
          </div>
        </ContainerFlex>
      )}
      <ClientListTable
        {...listProps}
        onRowClick={(item) => {
          navigate(`/clients/${item.titanClientId}`);
        }}
        stateAssets={{
          emptySearchText: "Nu am gasit nici un medic",
          emptyIcon: <UserAddIcon size={40} />,
          emptyTitle: "Momentan nu ai nici un medic adaugat",
          ...(user?.isAdmin
            ? {
                emptySubTitle:
                  "Adauga primul tau colaborator apasand pe butonul de jos",
                emptyAction: () => {
                  setIsOpen(true);
                },
                emptyActionText: "Adauga medic",
              }
            : {}),
        }}
      />

      <Portal
        isOpen={isOpen}
        onDismiss={() => {
          setIsOpen(false);
        }}
        title="Adauga Medic"
        overlay={true}
        width={560}
      >
        <ClientForm
          onClose={() => {
            setIsOpen(false);
          }}
          onSaved={() => {
            listProps.reload();
            setIsOpen(false);
          }}
        />
      </Portal>
    </>
  );
};

export default ClientList;
