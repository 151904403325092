import { FC } from "react";
import { IColumn } from "../../lib";
import {
  AnimatedAppIcon,
  Card,
  SearchIcon,
  Table,
  Typography,
} from "../../toolkit";
import TableEmptyState, { Props as EmptyStateProps } from "./TableEmptyState";

type IDocument = {
  key: string;
} & any;

export type EmptyTableStateProps = {
  emptySearchText?: string;
} & EmptyStateProps;

type Props = {
  items: IDocument[];
  columns: IColumn[];
  onRowClick?: (item?: any) => void;
  subTableComponent?: FC<{ row: any }>;

  loading: boolean;
  isFiltered: boolean;
  page: number;
  pageSize?: number;
  total: number;
  reload: () => void;
  changePage?: (page: number) => void;

  stateAssets: EmptyTableStateProps;

  isSubList?: boolean;
};

const TableList: FC<Props> = ({
  items,
  columns,
  total,
  isFiltered,
  page,
  pageSize,
  changePage,
  onRowClick,
  loading,
  stateAssets,
  isSubList,
  subTableComponent,
}) => {
  return loading ? (
    "Se incarca"
  ) : (
    <>
      {(total > 0 || isFiltered) && (
        <>
          {total > 0 && (
            <Table
              columns={columns}
              items={items}
              total={total}
              page={page}
              pageSize={pageSize}
              onPageChanged={changePage}
              onRowClick={onRowClick}
              subTableComponent={subTableComponent}
              container={true}
            />
          )}
          {total === 0 && (
            <Card container={true}>
              <AnimatedAppIcon>
                <SearchIcon size={40} />
              </AnimatedAppIcon>
              <Typography type="h5" variation="center">
                {stateAssets.emptySearchText}
              </Typography>
            </Card>
          )}
        </>
      )}
      {total === 0 && !isFiltered && (
        <>
          {isSubList ? (
            <Card container={true}>
              <TableEmptyState {...stateAssets} />
            </Card>
          ) : (
            <TableEmptyState {...stateAssets} />
          )}
        </>
      )}
    </>
  );
};

export default TableList;
