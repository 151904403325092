import { useState } from 'react';
import { API_URLS, generateMedicReport } from '../../lib';
import { TitanTender, TitanTenderCase } from '../../models';
import { useAppSelector } from '../../store';
import { useAppStoreHooks } from '../../toolkit';
import { textMessages } from '../../translations';

const useTender = (
  clientId: number,
  clientName: string,
  onSaved: () => void
) => {
  const [payTenderOptions, setPayTenderOptions] = useState<{
    tenderId?: number;
    step: 'list' | 'pay';
    caseTenderList?: TitanTenderCase[];
    selectedCases: number[];
    amountToPay: number;
  }>({
    step: 'list',
    selectedCases: [],
    amountToPay: 0,
  });
  const company = useAppSelector((state) => state.cache.company.company);
  const {
    getRequest,
    deleteRequest,
    getJson,
    notify,
    loadingStart,
    loadingEnd,
    postJson,
  } = useAppStoreHooks();

  const onDownload = async (
    tender: TitanTender,
    type: 'elements' | 'cases' | 'both'
  ) => {
    loadingStart();
    try {
      const response = await getRequest(API_URLS.titan.company.companyLogoLink);
      const logo = await response?.text();
      const tenderDetails = await getJson<TitanTender>(
        `${API_URLS.titan.tender.tender}/${tender.tenderId}`
      );
      generateMedicReport(
        {
          caseReports: tenderDetails.tenderCases,
          tenderCases: [],
          logoUrl: logo || '',
          name: clientName,
          from: tenderDetails.from,
          to: tenderDetails.to,
          price: tenderDetails.price!,
          paid: tenderDetails.paid!,
          createdBy: '',
          titanClientId: clientId,
        },
        company!,
        type
      );
      loadingEnd();
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, 'error', e.message);
    }
  };

  const onDelete = async (tenderId: number) => {
    loadingStart();
    try {
      await deleteRequest(`${API_URLS.titan.tender.tender}/${tenderId}`);
      loadingEnd();
      onSaved();
      notify('Devizul a fost sters', 'success');
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, 'error', e.message);
    }
    return false;
  };

  const setPayTender = async (tenderId?: number) => {
    setPayTenderOptions((prevState) => ({
      ...prevState,
      tenderId,
      step: 'list',
      selectedCases: [],
    }));

    if (tenderId !== undefined) {
      loadingStart();
      try {
        const tenderDetails = await getJson<TitanTender>(
          `${API_URLS.titan.tender.tender}/${tenderId}`
        );
        setPayTenderOptions((prevState) => ({
          ...prevState,
          caseTenderList: tenderDetails.tenderCases,
        }));
        loadingEnd();
      } catch (e: any) {
        loadingEnd();
        notify(textMessages.genericError, 'error', e.message);
      }
    }
  };

  const setStep = (step: 'list' | 'pay') => {
    setPayTenderOptions((prevState) => ({
      ...prevState,
      step,
    }));
  };

  const toggleSelectedCase = (titanCaseId: number) => {
    setPayTenderOptions((prevState) => {
      const selectedCases = prevState.selectedCases.includes(titanCaseId)
        ? prevState.selectedCases.filter((c) => c !== titanCaseId)
        : [...prevState.selectedCases, titanCaseId];
      const amountToPay =
        prevState.caseTenderList
          ?.filter((c) => selectedCases.includes(c.titanCaseId))
          .reduce((acc, current) => acc + current.price - current.paid, 0) || 0;
      return {
        ...prevState,
        selectedCases,
        amountToPay,
      };
    });
  };

  const onSelectAllCasesChanged = (checked?: boolean) => {
    if (checked) {
      setPayTenderOptions((prevState) => ({
        ...prevState,
        selectedCases: (prevState.caseTenderList || []).map(
          (c) => c.titanCaseId
        ),
        amountToPay:
          prevState.caseTenderList?.reduce(
            (acc, current) => acc + current.price - current.paid,
            0
          ) || 0,
      }));
    } else {
      setPayTenderOptions((prevState) => ({
        ...prevState,
        selectedCases: [],
        amountToPay: 0,
      }));
    }
  };

  const onGeneratePayment = async () => {
    loadingStart();
    try {
      await postJson<{}>(API_URLS.titan.tender.payment, {
        tenderId: payTenderOptions.tenderId!,
        amount: payTenderOptions.amountToPay,
        titanCaseIds: payTenderOptions.selectedCases,
      });
      setPayTender();
      onSaved();
      loadingEnd();
      notify(
        'Plata pe cazurile selectate a fost adaugata cu success!',
        'success'
      );
    } catch (e: any) {
      loadingEnd();
      notify(e.message, 'error');
    }
  };

  return {
    payTenderOptions,
    onDownload,
    onDeleteConfirm: onDelete,
    setPayTender,
    setStep,
    toggleSelectedCase,
    onSelectAllCasesChanged,
    onGeneratePayment,
  };
};

export default useTender;
