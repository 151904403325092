import { useState } from 'react';
import { debounce } from 'lodash';
import { ListCache, useAppDispatch } from '../../store';
import { TableProps } from '../types';

const useCacheList = <T>(
  cacheList: ListCache<T>,
  filterFunc: (searchTerm: string) => (row: any) => boolean,
  reloadAction: () => {
    payload: any;
    type: string;
  }
): TableProps => {
  const [tableState, setTableState] = useState<{
    page: number;
    total?: number;
    searchTerm?: string;
    filtered?: any[];
  }>({
    page: 0
  });
  const dispatch = useAppDispatch();
  const results = (cacheList.data || []);

  const setSearchTerm = debounce((searchTerm?: string) => {
    const filtered = !searchTerm ? undefined : results.filter(filterFunc(searchTerm));
    setTableState(prevState => ({
      ...prevState,
      filtered,
      searchTerm
    }));
  }, 500);


  const changePage = (page: number) => {
    setTableState(prevState => ({
      ...prevState,
      page
    }));
  };

  const reload = (reset?: boolean) => {
    dispatch(reloadAction());
    if(reset) {
      changePage(0);
    }
  };

  const final = (tableState.filtered || results);
  const start = tableState.page * 10;
  const end = final.length - 1 - start < 0 ? final.length - 1 : start + 10;
  return {
    loading: cacheList.loading,
    isFiltered: Boolean(tableState.searchTerm),
    page: tableState.page,
    results: final.slice(start, end),
    total: (tableState.filtered || results).length,
    setSearchTerm,
    setQueryParam: () => { },
    reload,
    changePage
  };
};

export default useCacheList;