import { useCallback, useEffect } from 'react';
import { initialListState, reloadList, RootState, setPaginatedList, setPaginatedListOffset, toggleLoading, useAppSelector } from '../../store';
import { ListProps } from './ListProps';
import useListLocalState from './useListLocalState';
import { useAppStoreHooks } from '../../toolkit';

const useList = <T>(url: string): ListProps<T> => {
  const list = useAppSelector((state: RootState) => state.paginatedList.value[url]) || initialListState;
  const { dispatch, getJson } = useAppStoreHooks()


  const reload = useCallback((params?: {
    queryParams?: { [key: string]: any; },
    sortingParams?: { [key: string]: any; };
  }) => {
    dispatch(reloadList({
      pageKey: url,
      ...(params || {})
    }));

  }, [url, dispatch]);

  const setQueryParam = useCallback((queryParams?: {
    [key: string]: any;
  }) => {
    reload({ queryParams: { ...(list.queryParams || {}), ...(queryParams || {}) } });
  }, [reload, list]);

  const setSortingParam = useCallback((sortingParams?: {
    [key: string]: any;
  }) => {
    reload({ sortingParams });
  }, [reload]);

  useEffect(() => {
    (async () => {
      if (list.loading || list.loaded) {
        return;
      }
      dispatch(toggleLoading({ pageKey: url, loading: true }));
      let internalUrl = url + (url.includes('?') ? '&' : '?');
      if (list.queryParams) {
        internalUrl = Object.keys(list.queryParams).reduce((accumulator, current) => {
          accumulator += `&${current}=${list.queryParams![current]}`;
          return accumulator;
        }, internalUrl);
      }
      if (list.sortingParams) {
        internalUrl = Object.keys(list.sortingParams).reduce((accumulator, current) => {
          accumulator += `&${current}=${list.sortingParams![current]}`;
          return accumulator;
        }, internalUrl);
      }
      const response = await getJson<T[]>(internalUrl);
      dispatch(setPaginatedList({
        pageKey: url,
        count: response?.length || 0,
        results: response || [],
        loaded: true
      }));
    })();
  }, [list.queryParams, list.sortingParams, list.loading, list.loaded, list.results.length, url, getJson, dispatch]);

  const onStoreOffset = useCallback((offset?: number) => {
    dispatch(setPaginatedListOffset({
      pageKey: url,
      offset: offset || -1
    }));
  }, [dispatch, url]);

  return useListLocalState<T>(list, url, dispatch, setQueryParam, setSortingParam, reload, undefined, onStoreOffset);
};


export default useList;