import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddIcon, Button, ContainerFlex, Tabs } from "../../../toolkit";
import { SearchInput } from "../../../components";
import { CaseList } from "../../cases";
import { API_URLS, usePaginatedListV2 } from "../../../lib";
import {
  TitanDiscountElement,
  TitanPayment,
  TitanTender,
} from "../../../models";
import ClientPayments from "../ClientPayments/ClientPayments";
import ClientDiscounts from "../ClientDiscounts/ClientDiscounts";
import ClientTenders from "../ClientTenders/ClientTenders";
import { useAppSelector } from "../../../store";

type Props = {
  clientId: number;
  clientName: string;
};

const adminTabs = [
  {
    id: "cases",
    label: "Cazuri",
  },
  {
    id: "payments",
    label: "Plati",
  },
  {
    id: "discount",
    label: "Discount-uri",
  },
  {
    id: "tender",
    label: "Devize",
  },
];

const managerTabs = [
  {
    id: "cases",
    label: "Cazuri",
  },
  {
    id: "discount",
    label: "Discount-uri",
  },
  {
    id: "tender",
    label: "Devize",
  },
];

const ClientDetailTables: FC<Props> = ({ clientId, clientName }) => {
  const user = useAppSelector((store) => store.auth.user);
  const [selectedTab, setSelectedTab] = useState<string>("cases");
  const [paymentsCRUD, setPaymentsCRUD] = useState<{
    isOpen: boolean;
    isDelete: boolean;
    payment?: TitanPayment;
  }>({
    isOpen: false,
    isDelete: false,
  });
  const [discountCRUD, setDiscountCRUD] = useState<{
    isOpen: boolean;
    isDelete: boolean;
    discount?: TitanDiscountElement;
  }>({
    isOpen: false,
    isDelete: false,
  });
  const [tenderCRUD, setTenderCRUD] = useState<{
    isOpen: boolean;
    tender?: TitanTender;
  }>({
    isOpen: false,
  });
  const navigate = useNavigate();
  const caseListProps = usePaginatedListV2(
    `${API_URLS.titan.cases.list}?titanClientId=${clientId}`,
    { sortingParams: { orderByDate: false } }
  );

  return (
    <>
      <ContainerFlex type="row" align="center" justify="space-between">
        <Tabs
          selected={selectedTab}
          items={user?.isAdmin ? adminTabs : managerTabs}
          onTabChanged={(id) => {
            setSelectedTab(id);
          }}
        />
        {selectedTab === "cases" && (
          <ContainerFlex type="row" align="center" spacing={2}>
            <SearchInput
              placeholder="Cauta"
              onChange={caseListProps.setSearchTerm}
            />
            <div>
              <Button
              type='button'
                size="medium"
                variant="contained"
                onClick={() => navigate(`/cases/${clientId}/new`)}
              >
                <AddIcon />
                Adauga caz
              </Button>
            </div>
          </ContainerFlex>
        )}
        {selectedTab === "payments" && (
          <Button
          type='button'
            size="medium"
            variant="contained"
            onClick={() => setPaymentsCRUD({ isOpen: true, isDelete: false })}
          >
            <AddIcon />
            Adauga plata
          </Button>
        )}
        {selectedTab === "discount" && user?.isAdmin && (
          <Button
          type='button'
            size="medium"
            variant="contained"
            onClick={() => setDiscountCRUD({ isOpen: true, isDelete: false })}
          >
            <AddIcon />
            Adauga discount
          </Button>
        )}
      </ContainerFlex>
      {selectedTab === "cases" && (
        <CaseList {...caseListProps} clientId={clientId} />
      )}
      {selectedTab === "payments" && (
        <ClientPayments
          clientId={clientId}
          isDelete={paymentsCRUD.isDelete}
          isOpen={paymentsCRUD.isOpen}
          payment={paymentsCRUD.payment}
          onDelete={(payment) =>
            setPaymentsCRUD({ isOpen: true, isDelete: true, payment })
          }
          onEdit={(payment) =>
            setPaymentsCRUD({ isOpen: true, isDelete: false, payment })
          }
          onReset={() => setPaymentsCRUD({ isOpen: false, isDelete: false })}
          onAdd={() => setPaymentsCRUD({ isOpen: true, isDelete: false })}
        />
      )}
      {selectedTab === "discount" && (
        <ClientDiscounts
          clientId={clientId}
          isDelete={discountCRUD.isDelete}
          isOpen={discountCRUD.isOpen}
          discount={discountCRUD.discount}
          onDelete={(discount) =>
            setDiscountCRUD({ isOpen: true, isDelete: true, discount })
          }
          onEdit={(discount) =>
            setDiscountCRUD({ isOpen: true, isDelete: false, discount })
          }
          onReset={() => setDiscountCRUD({ isOpen: false, isDelete: false })}
          onAdd={() => setDiscountCRUD({ isOpen: true, isDelete: false })}
        />
      )}
      {selectedTab === "tender" && (
        <ClientTenders
          clientId={clientId}
          clientName={clientName}
          isOpen={tenderCRUD.isOpen}
          tender={tenderCRUD.tender}
          onDelete={(tender) => setTenderCRUD({ isOpen: true, tender })}
          onReset={() => setTenderCRUD({ isOpen: false })}
        />
      )}
    </>
  );
};

export default ClientDetailTables;
