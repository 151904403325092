import { FC } from 'react';
import { API_URLS, usePaginatedListV2 } from '../../../lib';
import PaymentList from '../PaymentList/PaymentList';
import { Portal } from '../../../toolkit';
import { TitanPayment } from '../../../models';
import PaymentCRUD from '../PaymentCRUD/PaymentCRUD';

type Props = {
  clientId: number;
  isOpen: boolean;
  isDelete: boolean;
  payment?: TitanPayment;
  onEdit: (payment: TitanPayment) => void;
  onDelete: (payment: TitanPayment) => void;
  onReset: () => void;
  onAdd: () => void;
};

const ClientPayments: FC<Props> = ({ clientId, isOpen, isDelete, payment, onEdit, onDelete, onReset, onAdd }) => {
  const paymentListProps = usePaginatedListV2(`${API_URLS.titan.clients.payments.list}?titanClientId=${clientId}`);

  return (
    <>
      <PaymentList {...paymentListProps} onEdit={onEdit} onDelete={onDelete} onAdd={onAdd}/>
      <Portal
        isOpen={isOpen}
        onDismiss={onReset}
        title={isDelete ? 'Sterge plata' : payment ? 'Modifica plata' : "Adauga Plata"}
        overlay={true}
        width={560}
      >
        <PaymentCRUD
          clientId={clientId}
          isDelete={isDelete}
          payment={payment}
          onClose={onReset}
          onRefresh={() => {
            paymentListProps.reload();
            onReset();
          }} />
      </Portal>
    </>
  );
};

export default ClientPayments;