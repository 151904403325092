import { useCallback, useMemo, useState } from 'react';
import { TitanCase, TitanCaseElement, TitanCaseFile, TitanCaseFitting, TitanUserStage } from '../../models';
import { validations } from '../../lib';
import { useAppSelector } from '../../store';

export const validate = validations({
  titanClientId: [['required', 'Camp oligatoriu!']],
  patientName: [['required', 'Camp oligatoriu!']],
  deliveryAddress: [['required', 'Camp oligatoriu!']],
});

const useCaseBase = (clientId?: number) => {
  const clients = useAppSelector(state => state.cache.clients);
  const selectedClient = clients?.data?.find(c => c.titanClientId === clientId);

  const [step, setStep] = useState(1);
  const [caseForm, setCaseForm] = useState<{
    form: TitanCase;
    errors: {
      titanClientId?: string;
      patientName?: string;
      deliveryAddress?: string;
      caseDateTime?: string;
      color?: string;
    };
  }>({
    form: {
      titanClientId: clientId,
      titanClientName: '',
      patientName: '',
      notes: '',
      caseDateTime: (new Date()).toISOString(),
      deliveryAddress: selectedClient?.address,
      caseElements: [],
      caseFittings: [],
      caseTeeth: [],
      color: '',
      medicNotes: '',
      isFinished: false,
      caseStages: []
    } as TitanCase,
    errors: {},
  });
  const clientOptions = useMemo(() => clients?.data?.map(e => ({ key: e.titanClientId, name: `${e.lastName} ${e.firstName}` })), [clients]);

  const onStepChanged = (step: number) => {
    if (step === 2) {
      const errors = validate(caseForm.form);
      setCaseForm(prevState => ({
        ...prevState,
        errors
      }));
      if (Object.keys(errors).length) {
        return;
      }
    }
    setStep(step);
  };

  const onClientChange = (clientId: number) => {
    const client = clients.data?.find(c => c.titanClientId === clientId);
    setCaseForm(prevState => {
      delete prevState.errors.titanClientId;
      delete prevState.errors.deliveryAddress;
      return {
        ...prevState,
        errors: {
          ...prevState.errors
        },
        form: {
          ...prevState.form,
          titanClientId: client?.titanClientId || 0,
          deliveryAddress: client?.address || ''
        }
      };
    });
  };

  const onChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    setCaseForm(prevState => {
      const errors = { ...prevState.errors } as any;
      delete errors[target.name];
      return {
        ...prevState,
        errors: errors,
        form: {
          ...prevState.form,
          [target.name]: target.value
        }
      };
    });
  };

  const onColorChange = useCallback((color: string) => {
    setCaseForm(prevState => {
      const errors = { ...prevState.errors } as any;
      delete errors.color;
      return {
        ...prevState,
        errors,
        form: {
          ...prevState.form,
          color
        }
      };
    });
  }, [setCaseForm]);


  const onDateChange = useCallback((date: Date) => {
    setCaseForm(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        caseDateTime: date.toISOString()
      }
    }));
  }, [setCaseForm]);

  const onAssignedStagesChanged = useCallback((caseElement: TitanCaseElement, userStage: TitanUserStage) => {
    setCaseForm(prevState => {
      let caseStages = [...prevState.form.caseStages];
      const index = caseStages.findIndex(cs =>
        cs.elementId === caseElement.elementId && cs.stageId === userStage.stageId
      );

      if (index > -1) {
        caseStages[index] = {
          ...caseStages[index],
          titanUserId: userStage.userIdentifier
        };
      } else {
        caseStages = [
          ...caseStages,
          {
            elementId: caseElement.elementId,
            stageId: userStage.stageId,
            titanUserId: userStage.userIdentifier,
            elementName: caseElement.name,
            stageName: userStage.stageName,
            titanUserName: `${userStage.firstName} ${userStage.lastName}`
          }
        ];
      }

      return {
        ...prevState,
        form: {
          ...prevState.form,
          caseStages
        }
      };
    });
  }, [setCaseForm]);

  const onElementsChanged = useCallback((caseElements: TitanCaseElement[]) => {
    setCaseForm(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        caseElements
      }
    }));
  }, [setCaseForm]);

  const onFilesChanged = useCallback((files: (File | TitanCaseFile)[]) => {
    setCaseForm(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        files: files
      }
    }));
  }, [setCaseForm]);

  const onFittingsChanged = useCallback((fittings: TitanCaseFitting[]) => {
    setCaseForm(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        caseFittings: fittings
      }
    }));
  }, [setCaseForm]);

  return {
    step,
    caseForm,
    setCaseForm,
    clientOptions,
    onStepChanged,
    onClientChange,
    onChange,
    onColorChange,
    onAssignedStagesChanged,
    onElementsChanged,
    onFilesChanged,
    onFittingsChanged,
    onDateChange,
  };
};

export default useCaseBase;
