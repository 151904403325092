import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AddIcon,
  Button,
  ContainerFlex,
  Tabs,
  UserAddIcon,
} from "../../../toolkit";
import { SearchInput } from "../../../components";
import { CaseList } from "../../cases";
import { API_URLS, usePaginatedListV2 } from "../../../lib";
import {
  TitanDiscountElement,
  TitanPayment,
  TitanTender,
} from "../../../models";
import ClientPayments from "../ClientPayments/ClientPayments";
import ClientDiscounts from "../ClientDiscounts/ClientDiscounts";
import ClientTenders from "../ClientTenders/ClientTenders";
import { useAppSelector } from "../../../store";
import ClientList from "../ClientList/ClientList";
import ClientListTable from "../ClientList/ClientListTable";

type Props = {
  clinicId: number;
  clinicName: string;
};

const adminTabs = [
  {
    id: "clients",
    label: "Medici",
  },
  {
    id: "cases",
    label: "Cazuri",
  },
  {
    id: "payments",
    label: "Plati",
  },
  {
    id: "discount",
    label: "Discount-uri",
  },
  {
    id: "tender",
    label: "Devize",
  },
];

const managerTabs = [
  {
    id: "cases",
    label: "Cazuri",
  },
  {
    id: "discount",
    label: "Discount-uri",
  },
    {
      id: "tender",
      label: "Devize",
    },
];

const ClinicDetailTables: FC<Props> = ({ clinicId, clinicName }) => {
  const user = useAppSelector((store) => store.auth.user);
  const [selectedTab, setSelectedTab] = useState<string>("clients");
  const [paymentsCRUD, setPaymentsCRUD] = useState<{
    isOpen: boolean;
    isDelete: boolean;
    payment?: TitanPayment;
  }>({
    isOpen: false,
    isDelete: false,
  });
  const [discountCRUD, setDiscountCRUD] = useState<{
    isOpen: boolean;
    isDelete: boolean;
    discount?: TitanDiscountElement;
  }>({
    isOpen: false,
    isDelete: false,
  });
  const [tenderCRUD, setTenderCRUD] = useState<{
    isOpen: boolean;
    tender?: TitanTender;
  }>({
    isOpen: false,
  });
  const navigate = useNavigate();
  const clientListProps = usePaginatedListV2(
    `${API_URLS.titan.clients.list}?clinicId=${clinicId}`,
    { sortingParams: { orderByDate: false } }
  );
  const caseListProps = usePaginatedListV2(
    `${API_URLS.titan.cases.list}?clinicId=${clinicId}`,
    { sortingParams: { orderByDate: false } }
  );

  return (
    <>
      <ContainerFlex type="row" align="center" justify="space-between">
        <Tabs
          selected={selectedTab}
          items={user?.isAdmin ? adminTabs : managerTabs}
          onTabChanged={(id) => {
            setSelectedTab(id);
          }}
        />
        {selectedTab === "clients" && (
          <ContainerFlex type="row" align="center" spacing={2}>
            <SearchInput
              placeholder="Cauta"
              onChange={clientListProps.setSearchTerm}
            />
            <div>
              <Button
              type='button'
                size="medium"
                variant="contained"
                onClick={() => {
                  // open add dialog
                }}
              >
                <AddIcon />
                Adauga medic
              </Button>
            </div>
          </ContainerFlex>
        )}
        {selectedTab === "cases" && (
          <ContainerFlex type="row" align="center" spacing={2}>
            <SearchInput
              placeholder="Cauta"
              onChange={caseListProps.setSearchTerm}
            />
            <div>
              <Button
              type='button'
                size="medium"
                variant="contained"
                onClick={() => navigate(`/cases/${clinicId}/new`)}
              >
                <AddIcon />
                Adauga caz
              </Button>
            </div>
          </ContainerFlex>
        )}
        {selectedTab === "payments" && (
          <Button
          type='button'
            size="medium"
            variant="contained"
            onClick={() => setPaymentsCRUD({ isOpen: true, isDelete: false })}
          >
            <AddIcon />
            Adauga plata
          </Button>
        )}
        {selectedTab === "discount" && user?.isAdmin && (
          <Button
          type='button'
            size="medium"
            variant="contained"
            onClick={() => setDiscountCRUD({ isOpen: true, isDelete: false })}
          >
            <AddIcon />
            Adauga discount
          </Button>
        )}
      </ContainerFlex>
      {selectedTab === "clients" && (
        <ClientListTable
          {...clientListProps}
          clinicId={clinicId}
          onRowClick={(item) => {
            navigate(`/clients/${item.titanClientId}`);
          }}
          stateAssets={{
            emptyIcon: <UserAddIcon size={40} />,
            emptyTitle: "Momentan nu ai nici un medic asociat acestei clinici",
            ...(user?.isAdmin
              ? {
                  emptySubTitle:
                    "Adauga primul tau colaborator apasand pe butonul de jos",
                  emptyAction: () => {
                    // setIsOpen(true);
                  },
                  emptyActionText: "Adauga medic",
                }
              : {}),
          }}
        />
      )}
      {/* {selectedTab === "cases" && (
        <CaseList {...caseListProps} clinicId={clinicId} />
      )}
      {selectedTab === "payments" && (
        <ClientPayments
          clientId={clientId}
          isDelete={paymentsCRUD.isDelete}
          isOpen={paymentsCRUD.isOpen}
          payment={paymentsCRUD.payment}
          onDelete={(payment) =>
            setPaymentsCRUD({ isOpen: true, isDelete: true, payment })
          }
          onEdit={(payment) =>
            setPaymentsCRUD({ isOpen: true, isDelete: false, payment })
          }
          onReset={() => setPaymentsCRUD({ isOpen: false, isDelete: false })}
          onAdd={() => setPaymentsCRUD({ isOpen: true, isDelete: false })}
        />
      )}
      {selectedTab === "discount" && (
        <ClientDiscounts
          clientId={clientId}
          isDelete={discountCRUD.isDelete}
          isOpen={discountCRUD.isOpen}
          discount={discountCRUD.discount}
          onDelete={(discount) =>
            setDiscountCRUD({ isOpen: true, isDelete: true, discount })
          }
          onEdit={(discount) =>
            setDiscountCRUD({ isOpen: true, isDelete: false, discount })
          }
          onReset={() => setDiscountCRUD({ isOpen: false, isDelete: false })}
          onAdd={() => setDiscountCRUD({ isOpen: true, isDelete: false })}
        />
      )}
      {selectedTab === "tender" && (
        <ClientTenders
          clientId={clientId}
          clientName={clientName}
          isOpen={tenderCRUD.isOpen}
          tender={tenderCRUD.tender}
          onDelete={(tender) => setTenderCRUD({ isOpen: true, tender })}
          onReset={() => setTenderCRUD({ isOpen: false })}
        />
      )} */}
    </>
  );
};

export default ClinicDetailTables;
