import { AnyAction, Dispatch } from 'redux';
import { debounce } from 'lodash';
import { ListState } from './ListState';
import { ListProps } from './ListProps';
import { addPaginatedItem, editPaginatedItem, removePaginatedItem } from '../../store';
import { MutableRefObject } from 'react';

const useListLocalState = <T>(
  list: ListState<T>,
  pageKey: string,
  dispatch: Dispatch<AnyAction>,
  setQueryParam: (queryParams?: {
    [key: string]: any;
  }) => void,
  setSortingParam: (sortingParams?: {
    [key: string]: any;
  }) => void,
  reload: () => void,
  offsetRef?: MutableRefObject<number | undefined>,
  onStoreOffset?: (offset?: number) => void
): ListProps<T> => {
  const setSearchTerm = debounce((searchTerm: string) => {
    setQueryParam({ ...list.queryParams, searchTerm });
  }, 500);

  const addItem = (item: T) => {
    dispatch(addPaginatedItem({ pageKey, item }));
  };

  const removeItem = (id: number, fieldId: string) => {
    dispatch(removePaginatedItem({ pageKey, id, fieldId }));
  };

  const editItem = (savedItem: T, fieldId: string) => {
    dispatch(editPaginatedItem({ pageKey, savedItem, fieldId }));
  };

  return {
    listProps: list,
    addItem,
    removeItem,
    editItem,
    setQueryParam,
    setSortingParam,
    setSearchTerm,
    reload,
    offsetRef,
    onStoreOffset
  };
};


export default useListLocalState;