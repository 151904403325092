import { FC } from 'react';

type Props = {
  size?: number;
};

const CarretDownIcon: FC<Props> = ({ size }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path d="M12.0771 6.11975L8.79714 2.83975C8.75873 2.79965 8.71261 2.76773 8.66154 2.74593C8.61047 2.72413 8.55552 2.71289 8.49999 2.71289C8.44447 2.71289 8.38952 2.72413 8.33845 2.74593C8.28738 2.76773 8.24126 2.79965 8.20285 2.83975L4.92285 6.11975" fill="#0F2D3E" />
      <path d="M12.0771 9.87891L8.79714 13.1589C8.75873 13.199 8.71261 13.2309 8.66154 13.2527C8.61047 13.2745 8.55552 13.2858 8.49999 13.2858C8.44447 13.2858 8.38952 13.2745 8.33845 13.2527C8.28738 13.2309 8.24126 13.199 8.20285 13.1589L4.92285 9.87891" fill="#667085" />
    </svg>
  );
};

export default CarretDownIcon;