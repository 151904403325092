import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { APP_LOCAL_STORAGE_KEYS } from "./constants";

export interface AppSettingsState {
  defaultVitaColor: number;
  defaultCalendarView: string | null;
  collapsedMenu: boolean;
  collapsedCaseFields: boolean;
  collapsedClientDetails: boolean;
  features: {
    stages: boolean;
    clinics: boolean;
  };
}

const initialState: AppSettingsState = {
  defaultVitaColor: 0,
  defaultCalendarView: null,
  collapsedMenu: false,
  collapsedCaseFields: false,
  collapsedClientDetails: false,
  features: {
    stages: false,
    clinics: false,
  },
};

export const setSettings = (settings: AppSettingsState) => {
  // TODO: Move to middleware
  // setTimeout(() => {

  localStorage.setItem(
    APP_LOCAL_STORAGE_KEYS.SETTINGS,
    JSON.stringify(settings)
  );
  // }, 0);
};

export const appSettingsSlice = createSlice({
  name: "appSettings",
  initialState,
  reducers: {
    updateDefaultVitaColor: (state, action: PayloadAction<number>) => {
      state.defaultVitaColor = action.payload;
      setSettings(state);
    },
    updateDefaultCalendarView: (state, action: PayloadAction<string>) => {
      state.defaultCalendarView = action.payload;
      setSettings(state);
    },
    toggleMenu: (state) => {
      state.collapsedMenu = !state.collapsedMenu;
      setSettings(state);
    },
    toggleCaseFields: (state) => {
      state.collapsedCaseFields = !state.collapsedCaseFields;
      setSettings(state);
    },
    toggleClientDetails: (state) => {
      state.collapsedClientDetails = !state.collapsedClientDetails;
      setSettings(state);
    },
    toggleFeature: (
      state,
      action: PayloadAction<{
        feature: "stages" | "clinics";
        value?: boolean;
      }>
    ) => {
      state.features = {
        ...state.features,
        [action.payload.feature]:
          action.payload.value !== undefined
            ? action.payload.value
            : !state.features[action.payload.feature],
      };
      setSettings(state);
    },
  },
});

export const {
  updateDefaultVitaColor,
  updateDefaultCalendarView,
  toggleMenu,
  toggleCaseFields,
  toggleClientDetails,
  toggleFeature,
} = appSettingsSlice.actions;

export default appSettingsSlice.reducer;
