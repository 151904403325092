import { FC, useState } from 'react';
import { TitanUser } from '../../../models';
import { AnimatedAppIcon, Button, ContainerFlex, TrashIcon, Typography, Portal, AddIcon } from '../../../toolkit';
import { useUserDelete } from '../../hooks';
import { setRefreshUsers, useAppSelector } from '../../../store';
import { useCacheList } from '../../../lib';
import UserList from '../UserList/UserList';
import UserForm from '../UserForm/UserForm';
import { SearchInput } from '../../../components';
import UserStagesForm from '../UserForm/UserStagesForm';

const filter = (searchTerm: string) =>
  (row: TitanUser) => {
    return `${row.firstName} ${row.lastName}`.toLowerCase().includes(searchTerm.toLowerCase());
  };

const CRUD_TITLE = {
  'add': "Adauga angajat",
  'edit': 'Modifica angajat',
  'delete': 'Sterge angajat',
  'configure-stages': 'Configureaza etape',
};


const UsersCRUD: FC = () => {
  const [usersCRUD, setUsersCRUD] = useState<{
    isOpen: boolean;
    type?: 'add' | 'edit' | 'delete' | 'configure-stages';
    user?: TitanUser;
  }>({
    isOpen: false,
  });
  const users = useAppSelector(state => state.cache.users);
  const listProps = useCacheList(users, filter, setRefreshUsers);
  const { onDelete } = useUserDelete(() => {
    setUsersCRUD({ isOpen: false });
    listProps.reload(true);
  });

  return (
    <>
      {(listProps.total > 0 || listProps.isFiltered) && (
        <ContainerFlex type='row' align='center' justify='space-between' style={{ width: 1200, margin: '0 auto' }}>
          <Typography type='h5' variation="center">{listProps.total} Utilizatori</Typography>
          <ContainerFlex type='row' spacing={2}>
            <SearchInput placeholder='Cauta' onChange={searchTerm => { listProps.setSearchTerm(searchTerm); }} />
            <div>
              <Button type='button' size='medium' variant='contained' onClick={() => setUsersCRUD({ isOpen: true, type: 'add' })}><AddIcon />Adauga angajat</Button>
            </div>
          </ContainerFlex>
        </ContainerFlex>
      )}
      <UserList
        {...listProps}
        onOpenModal={(type, user) => setUsersCRUD({ isOpen: true, type, user })}
      />
      <Portal
        isOpen={usersCRUD.isOpen}
        onDismiss={() => setUsersCRUD({ isOpen: false })}
        title={CRUD_TITLE[usersCRUD.type!]}
        overlay={true}
        width={560}
      >
        <>
          {usersCRUD.type === 'delete' && (
            <ContainerFlex type='column' spacing={3}>
              <AnimatedAppIcon variation='danger'>
                <TrashIcon size={40} />
              </AnimatedAppIcon>
              <Typography type='p' variation='center'>
                Acest angajat va fi sters din baza de date.
              </Typography>
              <ContainerFlex spacing={1} type='row' justify='end'>
                <Button size='medium' variant='outlined' type="button" onClick={() => setUsersCRUD({ isOpen: false })}>Renunta</Button>
                <Button type='button' size='medium' variant='danger' onClick={() => onDelete(usersCRUD.user?.userIdentifier!)}>Sterge</Button>
              </ContainerFlex>
            </ContainerFlex>
          )}
          {['add', 'edit'].includes(usersCRUD.type || '') && (
            <UserForm
              user={usersCRUD.user!}
              onClose={() => setUsersCRUD({ isOpen: false })}
              onSaved={() => {
                setUsersCRUD({ isOpen: false });
                listProps.reload();
              }}
            />
          )}
          {usersCRUD.type === 'configure-stages' && (
            <UserStagesForm
              user={usersCRUD.user}
              onClose={() => setUsersCRUD({ isOpen: false })}
              onSaved={() => {
                setUsersCRUD({ isOpen: false });
                listProps.reload();
              }}
            />
          )}
        </>
      </Portal>
    </>
  );
};

export default UsersCRUD;