export const DENTOPS_ROLES = {
  OWNER: 'Owner',
  ADMIN: 'DentopsAdmin',
  MANAGER: 'DentopsManager',
  TECHNICIAN: 'DentopsTechnician',
  DATAENTRY: 'DentopsDataEntry',
};

export const API_URLS = {
  identity: {
    login: '/api/identity/Security/login',
    refresh: '/api/identity/Security/refresh',
    forgotPassword: '/api/identity/User/forgot-password',
    resetPassword: '/api/identity/User/reset-password',
    signUp: '/api/identity/sign-up',
    changePassword: '/api/identity/User/change-password',
    user: {
      user: '/api/identity/User',
      roles: '/api/identity/User/user-roles',
      // downloadReport: '/api/titan/TitanUser/DownloadReport',
      // titanUserReportQuery: '/api/titan/TitanUser/TitanUserReportQuery',
      activateUser: '/api/identity/User/activate',
      toggleStatus: '/api/identity/User/toggle-status',
      
    },
  },
  platform: {
    subsciption: {
      subsciption: '/api/platform/subscription',
      removeCancellation: '/api/platform/subscription/remove-cancellation',
      checkout: '/api/platform/checkout-session',
    },
    paymentMethod: '/api/platform/payment-method',
    invoiceList: '/api/platform/platform-invoice',
    downloadInvoice: '/api/platform/platform-invoice',
    titanProduct: '/api/platform/product/titan',
  },
  titan: {
    calendar: '/api/titan/Calendar',
    clients: {
      list: '/api/titan/TitanClient/List',
      create: '/api/titan/TitanClient/Create',
      details: '/api/titan/TitanClient/Details',
      update: '/api/titan/TitanClient/Update',
      delete: '/api/titan/TitanClient/Delete',
      summary: '/api/titan/TitanClient/Summary',
      payments: {
        list: '/api/titan/TitanClient/Payments',
        create: '/api/titan/TitanClient/AddPayment',
        update: '/api/titan/TitanClient/UpdatePayment',
        delete: '/api/titan/TitanClient/DeletePayment',
      },
    },
    clinic: {
      clinic: '/api/titan/Clinic',
      summary: '/api/titan/Clinic/Summary',
    },
    elements: {
      list: '/api/titan/Element/List',
      update: '/api/titan/Element/Update',
      create: '/api/titan/Element/Create',
      delete: '/api/titan/Element/Delete',
      discount: {
        list: '/api/titan/ElementDiscount/List',
        create: '/api/titan/ElementDiscount/Create',
        update: '/api/titan/ElementDiscount/Update',
        delete: '/api/titan/ElementDiscount/Delete',
      },
    },
    user: {
      downloadReport: '/api/titan/TitanUser/DownloadReport',
      titanUserReportQuery: '/api/titan/TitanUser/TitanUserReportQuery',
    },
    stage: {
      stage: '/api/titan/Stage',
      userStage: '/api/titan/Stage/UserStage',
    },
    company: {
      company: '/api/titan/Company',
      assignedCases: '/api/titan/Company/CompanyUsersCurrentWork',
      companyLogoLink: '/api/titan/Company/CompanyLogoLink',
      upload: '/api/titan/Company/UploadCompanyLogo',
      update: '/api/titan/Company/Update',
      statistics: '/api/titan/Company/CompanyStatistics',
    },
    cases: {
      case: '/api/titan/TitanCase',
      create: '/api/titan/TitanCase/Create',
      details: '/api/titan/TitanCase/CaseDetails',
      list: '/api/titan/TitanCase/CaseList',
      fileLink: '/api/titan/TitanCase/CaseFileLink',
      downloadCaseFile: '/api/titan/TitanCase/DownloadCaseFile',
      deleteCaseFile: '/api/titan/TitanCase/DeleteCaseFile',
      addCaseFile: '/api/titan/TitanCase/AddCaseFile',
      downloadCertificateFile: '/api/titan/TitanCase/DownloadCertificateFile',
      toggleIsFinished: '/api/titan/TitanCase/ToggleIsFinished',
      export: '/api/titan/TitanCase/Export',
      mainDetails: '/api/titan/TitanCase/UpdateCaseMainDetails',
      updateCaseElements: '/api/titan/TitanCase/UpdateCaseElements',
      updateCaseStages: '/api/titan/TitanCase/UpdateCaseStages',
      payCase: '/api/titan/TitanCase/PayCase',
      toggleIsCaseStageFinished:
        '/api/titan/TitanCase/ToggleIsCaseStageFinished',
      availableUsersForElements:
        '/api/titan/TitanCase/AvailableUsersForElements',
      updateCaseNotes: '/api/titan/TitanCase/UpdateCaseNotes',
    },
    caseStages: {
      update: '/api/titan/TitanCase/UpdateCaseStages',
    },
    caseElementStages: {
      list: '/api/titan/TitanCase/CaseElementsStages',
    },
    payment: {
      // productsWithPrices: '/api/titan/Payment/ProductsWithPrices',
      // cancelSubscription: '/api/titan/Payment/CancelSubscription',
      // restoreSubscription: '/api/titan/Payment/RemoveCancelSubscription',
      // createCheckout: '/api/titan/Payment/CreateCheckout',
      // downloadInvoice: '/api/titan/Payment/DownloadInvoice',
      // invoiceList: '/api/titan/Payment/InvoiceList',
    },
    tender: {
      tender: '/api/titan/Tender',
      payment: '/api/titan/Tender/AddTenderPayment',
      cases: '/api/titan/Tender/TenderCases',
    },
    material: {
      material: '/api/titan/Material',
    },
    fitting: {
      fitting: '/api/titan/Fitting',
      toggleIsFinished: '/api/titan/Fitting/ToggleIsFinished',
    },
  },
};
