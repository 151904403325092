import { FC } from 'react';
import { Button, Table } from '../../../toolkit';
import { TitanInvoice } from '../../../models';
import { ListProps, prettyDate } from '../../../lib';
import { IColumn } from '../../../lib';
import { useSubscriptionInvoice } from '../../hooks';
import { useAppSelector } from '../../../store';

interface IDocument extends TitanInvoice {
  key: string;
}

type Props = ListProps<TitanInvoice>;

const InvoiceList: FC<Props> = ({ listProps }) => {
  const company = useAppSelector(state => state.cache.company.company);
  const { onDownloadInvoice } = useSubscriptionInvoice(company?.name || '');
  const columns: IColumn[] = [
    {
      key: 'no',
      name: '#',
      fieldName: 'invoiceNo',
    },
    {
      key: 'date',
      name: 'Data',
      fieldName: 'date',
      onRender: (item: IDocument) => (
        <span>{prettyDate(item.issueDate)}</span>
      )
    },
    {
      key: 'amountDue',
      name: 'Suma',
      fieldName: 'amountDue',
      onRender: (item: IDocument) => (
        <span>{item.amount.toFixed(2)}</span>
      )
    },
    // {
    //   key: 'amountPaid',
    //   name: 'Suma platita',
    //   fieldName: 'amountPaid',
    //   onRender: (item: IDocument) => (
    //     <span>{item..toFixed(2)}</span>
    //   )
    // },
    {
      key: 'actions',
      name: '',
      fieldName: 'actions',
      onRender: (item: IDocument) =>
        <div>
          <Button type='button' size='small' variant='outlined' onClick={() => onDownloadInvoice(item)}>Descarca Factura</Button>
        </div>
    }
  ];

  const items = listProps.results.map((item: TitanInvoice, index: number) => {
    return {
      key: item.invoiceNo,
      index: index + 1,
      ...item
    };
  });
  return (
    <Table columns={columns} items={items} />
  );
};

export default InvoiceList;