import { useState } from 'react';
import { TitanClient } from '../../models';
import { API_URLS, validations } from '../../lib';
import { useAppStoreHooks } from '../../toolkit';
import { textMessages } from '../../translations';
import { setRefreshClients } from '../../store';

const validate = validations({
  firstName: [['required', 'Camp oligatoriu!']],
  lastName: [['required', 'Camp oligatoriu!']],
  email: [['email', 'Adresa de email nu este valida!']],
  phone: [['required', 'Camp oligatoriu!']],
  address: [['required', 'Camp oligatoriu!']],
  city: [['required', 'Camp oligatoriu!']],
  bankAccount: [['maxLength', 'Lungimea maxima este de 24 de caractere', '24']],
  fiscalCode: [['maxLength', 'Lungimea maxima este de 15 de caractere', '15']],
});

const useClient = (onSaved: () => void, client?: TitanClient) => {
  const [clientForm, setClientForm] = useState<{
    form: TitanClient;
    errors: {
      firstName?: string;
      lastName?: string;
      email?: string;
      phone?: string;
      address?: string;
      city?: string;
      bankAccount?: string;
      fiscalCode?: string;
    };
  }>({
    form: client || ({} as TitanClient),
    errors: {},
  });

  const { postJson, notify, dispatch, loadingStart, loadingEnd } =
    useAppStoreHooks();

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    setClientForm((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [target.name]: target.value,
      },
    }));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors = validate(clientForm.form);
    setClientForm((prevState) => ({
      ...prevState,
      errors,
    }));
    if (Object.keys(errors).length) {
      return;
    }
    loadingStart();
    try {
      if (clientForm.form.titanClientId) {
        await postJson<any>(API_URLS.titan.clients.update, clientForm.form);
        notify('Clientul a fost modificat.', 'success');
      } else {
        await postJson<TitanClient>(
          API_URLS.titan.clients.create,
          clientForm.form
        );
        notify('Clientul a fost adaugat.', 'success');
      }
      onSaved();
      loadingEnd();
      dispatch(setRefreshClients());
    } catch (e: any) {
      loadingEnd();
      notify(textMessages.genericError, 'error', e.message);
    }
  };
  return {
    clientForm,
    onChange,
    onSubmit,
  };
};

export default useClient;
