import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URLS, prettyDate, usePaginatedListV2 } from '../../lib';
import { PageLayout } from '../../layout';
import { AddIcon, AnimatedAppIcon, Button, ContainerFlex, DateRangePicker, Portal, Typography, DownloadIcon } from '../../toolkit';
import { SearchInput } from '../../components';
import { CaseList, useExportCases } from '../../modules';

const CasesPage: FC = () => {
  const navigate = useNavigate();
  const listProps = usePaginatedListV2(API_URLS.titan.cases.list, { sortingParams: { orderByDate: false } });
  const { exportDialog, onExportCases, toggleExportDialog, onExportDatesChange } = useExportCases();

  const onDatesSelected = (startDate?: Date, endDate?: Date) => {
    onExportDatesChange(startDate, endDate);
    listProps.setQueryParam({
      from: startDate && startDate.toISOString(),
      to: endDate && endDate.toISOString()
    });
  };

  return (
    <>
      <PageLayout title="Cazuri" tabs={(
        <ContainerFlex type='row' spacing={1}>
          <DateRangePicker defaultStartDate={exportDialog.startDate} defaultEndDate={exportDialog.endDate} onDatesSelected={onDatesSelected} />
          <Button type='button' size="small" variant='outlined' onClick={toggleExportDialog} disabled={!(Boolean(exportDialog.startDate) && Boolean(exportDialog.endDate))}>
            <DownloadIcon />
            Exporta cazurile
          </Button>
        </ContainerFlex>
      )}>
        {(listProps.total > 0 || listProps.isFiltered) && (
          <ContainerFlex type='row' justify='space-between' style={{ width: 1200, margin: '0 auto' }}>
              <Typography type='h5' variation="center">{listProps.total} Cazuri</Typography>
              <ContainerFlex type='row' spacing={2}>
                <SearchInput placeholder='Cauta' onChange={searchTerm => { listProps.setSearchTerm(searchTerm); }} />
                <div>
                  <Button type='button' size='medium' variant='contained' onClick={() => { navigate('/cases/new'); }}><AddIcon />Adauga caz</Button>
                </div>
              </ContainerFlex>
          </ContainerFlex>
         )}
         <CaseList {...listProps} />
      </PageLayout>
      <Portal
        isOpen={exportDialog.isOpen}
        title="Exporta Cazurile"
        overlay={true}
        onDismiss={toggleExportDialog}
        width={560}
      >
        <ContainerFlex type='column' spacing={3}>
          <AnimatedAppIcon>
            <DownloadIcon size={40} />
          </AnimatedAppIcon>
          <div>
            <Typography type='p' variation='center'>
              Descarca in Excel lista de cazuri pentru perioada
            </Typography>
            <Typography type='p' variation='center' variation1='bold'>
            {prettyDate(exportDialog.startDate!)} - {prettyDate(exportDialog.endDate!)}
            </Typography>
          </div>
          <ContainerFlex spacing={1} type='row' justify='end'>
            <Button size='medium' variant='outlined' type="button" onClick={toggleExportDialog}>Renunta</Button>
            <Button type='button' size='medium' variant='contained' onClick={onExportCases}>Exporta</Button>
          </ContainerFlex>
        </ContainerFlex>
      </Portal>
    </>
  );
};

export default CasesPage;