import { FC } from "react";
import { IColumn, TableProps } from "../../../lib";
import { TitanClient } from "../../../models";
import { Typography } from "../../../toolkit";
import { EmptyTableStateProps, TableList } from "../../../components";

interface IDocument extends TitanClient {
  key: string;
}

type Props = {
  clinicId?: number;
  onRowClick: (item: IDocument) => void;
  stateAssets: EmptyTableStateProps;
} & TableProps;

const ClientListTable: FC<Props> = ({
  clinicId,
  onRowClick,
  stateAssets,
  ...listProps
}) => {
  const columns: IColumn[] = [
    {
      key: "no",
      name: "#",
      fieldName: "index",
      width: 70,
    },
    {
      key: "name",
      name: "Nume",
      fieldName: "name",
      // ...getSortingProps('orderByName', listProps),
      onRender: (item: IDocument) => (
        <div>
          <Typography type="p" variation="bold">
            {item.lastName} {item.firstName}
          </Typography>
          <Typography type="span" variation="secondary">
            {item.email}
          </Typography>
        </div>
      ),
    },
    {
      key: "city",
      name: "Oras",
      fieldName: "city",
      // ...getSortingProps('orderByEmail', listProps)
    },
    {
      key: "address",
      name: "Adresa",
      fieldName: "address",
    },
    {
      key: "phone",
      name: "Telefon",
      fieldName: "phone",
    },
    // {
    //   key: "notes",
    //   name: "Notite",
    //   fieldName: "notes",
    //   onRender: (row) => <Typography type='span' >{row.notes}</Typography>
    // },
    // {
    //   key: "cases",
    //   name: "Cazuri",
    //   fieldName: "cases",
    //   width: 80,
    // },
    // {
    //   key: "elements",
    //   name: "Elemente",
    //   fieldName: "elements",
    //   width: 100,
    // },
  ];

  const items = listProps.results.map((item: TitanClient, index: number) => {
    return {
      key: item.titanClientId,
      index: listProps.page * 10 + index + 1,
      ...item,
    };
  });
  return (
    <TableList
      columns={columns}
      items={items}
      changePage={listProps.changePage}
      isFiltered={listProps.isFiltered}
      page={listProps.page}
      reload={listProps.reload}
      loading={listProps.loading}
      total={listProps.total}
      onRowClick={onRowClick}
      stateAssets={stateAssets}
      isSubList={true}
    />
  );
};

export default ClientListTable;
