import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PaginatedListState } from "../lib";

type BasePayload = {
  pageKey: string;
};

type UrlQueryParamsPayload = {
  queryParams?: { [key: string]: any };
  sortingParams?: { [key: string]: any };
} & BasePayload;

type PagePayload = {
  page: number;
} & BasePayload;

type LoadingPayload = {
  loading: boolean;
} & BasePayload;

type SetPaginatedListPayload = {
  count: number;
  results: Array<any>;
  loaded: boolean;
} & UrlQueryParamsPayload;

export const initialListV2State: PaginatedListState<any> = {
  loading: false,
  loaded: false,
  page: 0,
  results: [],
};

const initialState: {
  value: { [key: string]: PaginatedListState<any> };
} = {
  value: {},
};

export const paginatedListV2Slice = createSlice({
  name: "paginatedList",
  initialState,
  reducers: {
    reloadListV2: (state, action: PayloadAction<UrlQueryParamsPayload>) => {
      const { pageKey = "", ...rest } = action.payload || {};
      let newList: PaginatedListState<any> = initialListV2State;

      newList = { ...initialListV2State, ...rest };

      state.value = {
        ...state.value,
        [pageKey]: newList,
      };
    },
    setPageV2: (state, action: PayloadAction<PagePayload>) => {
      const { pageKey = "", ...rest } = action.payload || {};
      const listState = state.value[pageKey] || initialListV2State;
      let newList: PaginatedListState<any> = initialListV2State;

      newList = { ...listState, page: rest.page };

      state.value = {
        ...state.value,
        [pageKey]: newList,
      };
    },

    toggleLoadingV2: (state, action: PayloadAction<LoadingPayload>) => {
      const { pageKey = "", ...rest } = action.payload || {};
      const listState = state.value[pageKey] || initialListV2State;
      let newList: PaginatedListState<any> = initialListV2State;

      newList = { ...listState, loading: rest.loading };

      state.value = {
        ...state.value,
        [pageKey]: newList,
      };
    },
    setPaginatedListV2: (
      state,
      action: PayloadAction<SetPaginatedListPayload>
    ) => {
      const {
        pageKey = "",
        count,
        results,
        loaded,
        sortingParams,
      } = action.payload || {};
      const listState = state.value[pageKey] || initialListV2State;
      let newList: PaginatedListState<any> = initialListV2State;
      if (!(results instanceof Array)) {
        console.trace(results);
      }
      const results12 = [...(results || [])];
      newList = {
        ...listState,
        count: count,
        results: [...listState.results, ...results12],
        loading: false,
        loaded,
        sortingParams,
      };

      state.value = {
        ...state.value,
        [pageKey]: newList,
      };
    },
  },
});

export const { reloadListV2, setPageV2, toggleLoadingV2, setPaginatedListV2 } =
  paginatedListV2Slice.actions;

export default paginatedListV2Slice.reducer;
