import { FC } from 'react';
import { IColumn, TABLE_DEFAULTS, TableProps, formatMoney, prettyDate } from '../../../lib';
import { TitanPayment } from '../../../models';
import {  ContainerFlex, EditNoteIcon, IconButton, MoneyIcon, TrashIcon } from '../../../toolkit';
import { TableList } from '../../../components';

interface IDocument extends TitanPayment {
  key: string;
}

type Props = {
  onEdit: (payment: TitanPayment) => void;
  onDelete: (payment: TitanPayment) => void;
  onAdd: () => void;
} & TableProps;

const PaymentList: FC<Props> = (listProps) => {

  const columns: IColumn[] = [
    {
      key: 'amount',
      name: 'Suma',
      fieldName: 'amount',
      onRender: (item: IDocument) => {
        return formatMoney(item.amount);
      }
    },
    {
      key: 'createdDateTime',
      name: 'Data',
      fieldName: 'createdDateTime',
      onRender: (item: IDocument) => {
        return prettyDate(item.createdDateTime || '');
      }
    },
    {
      key: 'actions',
      name: '',
      fieldName: 'actions',
      width: 110,
      onRender: (item: IDocument) => {
        return (
          <ContainerFlex type='row' spacing={1}>
            <IconButton variant='outlined' onClick={() => listProps.onEdit(item)}>
              <EditNoteIcon />
            </IconButton>
            <IconButton variant='outlined' color='danger' onClick={() => listProps.onDelete(item)}>
              <TrashIcon />
            </IconButton>
          </ContainerFlex>
        );
      },
    }
  ];

  const items = listProps.results.map((item: TitanPayment, index: number) => {
    return {
      key: item.clientPaymentId,
      index: (listProps.page * 10) + index + 1,
      ...item
    };
  });


  return (
    <TableList
      columns={columns}
      items={items}
      changePage={listProps.changePage}
      isFiltered={listProps.isFiltered}
      page={listProps.page}
      pageSize={TABLE_DEFAULTS.pageSize}
      reload={listProps.reload}
      loading={listProps.loading}
      total={listProps.total}
      stateAssets={{
        emptySearchText: 'Nu a fost gasit nici o plata',
        emptyIcon: <MoneyIcon size={40} />,
        emptyTitle: 'Nu ai nici o plata adaugata pentru acest medic',
        emptySubTitle: 'Adauga o plata asociata acestui medic apasand butonul de mai jos',
        emptyAction: listProps.onAdd,
        emptyActionText: 'Adauga plata'
      }}
      isSubList={true}
    />
  );
};

export default PaymentList;